/**
 * @Description date and time formats
 * 
 * @Author Avinash Jaiswal
 * */
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class DateTimeUtil {
    dateFormat: any
    datePipe = new DatePipe("en-US");

    constructor() {
        this.dateFormat = 'yyyy-MM-dd'
    }
    
    // *************start code for 'ngbDatepicker'***********
    /**
     * @Description Create ngbDate object into javascript date object
     * @params Takes ngbDate object {day:'dd', month:'mm', year:'yyyy'}
     * @return return javascript date object  in format 'yyyy-mm-dd'
     * @Author Avinash Jaiswal
     **/
    dateObjectFromNgbDateObject(dateObj) {
        // console.log("dateObjectFromNgbDateObject : ")
        // console.log(dateObj)
        if (dateObj && (typeof dateObj == 'object') && !isNaN(dateObj.year)) {
            // console.log("if dateObjectFromNgbDateObject")
            return new Date(dateObj.year + '-' + dateObj.month + '-' + dateObj.day)
        }
        return ''
    }

    /**
     * @Description set date format in "YYYY-MM-DD" for post data
     *
     * @Author Avinash Jaiswal
     **/
    setDateFormatForPost(value) {
        // console.log("setDateFormatForPost : ")
        // console.log(value)
        if (value && (typeof value == 'object') && !isNaN(value.year)) {
            // console.log("setDateFormatForPost is not nan")
            let javaScriptDateObj = this.dateObjectFromNgbDateObject(value) //convert in format '2019-12-05T14:19:53.890907+05:30'
            // console.log("javaScriptDateObj");console.log("javaScriptDateObj")
            return this.datePipe.transform(javaScriptDateObj, this.dateFormat) // return according to api date format which decided by variable this.dateFormat 
        }
    }

    /**
     * @Description set manual date as 'MM/dd/yyyy'
     *
     * @Author Avinash Jaiswal
     * */
    manualSetDate (ref) {
        // console.log("-----date util manula set date------------")
        // console.log("ref._inputValue : ");console.log(ref._inputValue)
        // console.log("ref._model ");console.log(ref._model)

        if (ref._model && ref._model.hasOwnProperty('year')) {
            let ngbDt = ref._model
            ref._inputValue = `${ngbDt.month}/${ngbDt.day}/${ngbDt.year}`
        }

    }
    //  *************end code for 'ngbDatepicker'*************
}