import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class MultiselectService {
    hideShowMultiSelect: any = []
    multiSelectfldVales: any = []

    slctedOpt: any = []
    clickedOpt: any = []
    optedOpt: any = []

    hideShowList: any = []

    multiSlctFldNams:any = {
        tables: 'tables'
    }

    constructor() {
    }

    /**
     * @Description set multiselect dropdown intial data
     * 
     * @Author Avinash Jaiswal
     * */
    setIntialData() {
        for (const echFldNam in this.multiSlctFldNams) {
            if (this.multiSlctFldNams.hasOwnProperty(echFldNam)) {
                this.slctedOpt[echFldNam] = []
                this.slctedOpt[echFldNam][0] = ''
                this.optedOpt[echFldNam] = []
                this.clickedOpt[echFldNam] = []
                this.hideShowList[echFldNam] = []
                this.hideShowList[echFldNam][0] = false
            }
        }
    }

    /**
     * @Description hide select list from all row
     * 
     * @Author Avinash Jaiswal
     * */
    hideList() {
        for (var key in this.hideShowMultiSelect) {
            this.hideShowMultiSelect[key] = false
        }
        // this.hideShowMultiSelect.fill(false)

        for (const key in this.hideShowList) {
            if (this.hideShowList.hasOwnProperty(key)) {
                this.hideShowList[key].fill(false);
            }
        }
    }

    /**
     * @Description hide and show custom multiselect dropdown
     * 
     * @Author Avinash Jaiswal
     * */
    hideShowMultiSelct(event: any, fldNam, index) {
        event.stopPropagation()
        var clickedSlctRef = this.hideShowList[fldNam][index]
        this.hideList()
        this.hideShowList[fldNam][index] = !clickedSlctRef
    }


    /**
     * @Description show selected option from multiselect dropdown
     * 
     * @Author Avinash Jaiswal
     * */
    showClikedOptions(object,fldNam,rowIndx) {
        // console.log("object");console.log(object)
        // console.log("fldNam");console.log(fldNam)
        // console.log("rowIndx");console.log(rowIndx)

        var obj = {}
        if (object.hasOwnProperty('key')) {
            obj = {id : object.key, value : object.value}
        } else {
            obj = Object.assign({}, object)
        }

        if (object.hasOwnProperty('dining_table_no')) {
            obj = {id : object.id, value : object.dining_table_no}
        }

        // console.log("obj");console.log(obj)

        if ((this.optedOpt[fldNam] != void 0) == false) {
            this.optedOpt[fldNam] = []
        }
        this.optedOpt[fldNam][obj['id']] = obj['value']

        // console.log("this.optedOpt");console.log(this.optedOpt)

        if ((this.clickedOpt[fldNam][rowIndx] != void 0) == false) {
            for (var i=-1; i < rowIndx; i++) {
                this.clickedOpt[fldNam].push([])
            }
        }

        var index2 = this.clickedOpt[fldNam][rowIndx].indexOf(obj['id']);
        // console.log("index2 : " + index2);

        if (index2 == -1) {
            this.clickedOpt[fldNam][rowIndx].push(obj['id'])
        } else {
            delete this.clickedOpt[fldNam][rowIndx][index2]
        }

        if ((this.slctedOpt[fldNam] != void 0) == false) {
            this.slctedOpt[fldNam] = []
            for (var i=-1; i < rowIndx; i++) {
                this.slctedOpt[fldNam][i] = ''
            }
        }
        this.slctedOpt[fldNam][rowIndx] = ''
        this.clickedOpt[fldNam][rowIndx].forEach(element => {
            this.slctedOpt[fldNam][rowIndx] = `${this.slctedOpt[fldNam][rowIndx]} ${this.optedOpt[fldNam][element]}, `
        });
        // console.log("this.clickedOpt")
        // console.log(this.clickedOpt[fldNam][rowIndx])
        this.slctedOpt[fldNam][rowIndx] = this.slctedOpt[fldNam][rowIndx].slice(0, -2);
    }

    /**
     * @Description Close select description
     *
     * @Author : Avinash Jaiswal
     *
     * */
    closSelctDescription(event: any) {
        if (!event.target.getAttribute("itsSlctChoiceLi")) {
            this.hideList()
        }
    }
}
